(() => {

  class CarouselNavItem {
    constructor(parent,target) {
      this.parent = parent;
      this.target = target;
      this.view = {
        root: undefined
      }
      this.active = false;
      this.build();
      this.setHandlers();
    }

    build = () => {
      const carouselControlsItem = document.createElement("li");
      carouselControlsItem.classList.add(`nav-${this.target}`);
      carouselControlsItem.classList.add(`btn`);
      carouselControlsItem.innerText = this.target;
      this.parent.view.list.appendChild(carouselControlsItem);

      this.view.root = carouselControlsItem;
    }

    maybeSetActive = i => {
      this.active = i === this.target;
      if (this.active) {
        this.view.root.classList.add("active");
      } else {
        this.view.root.classList.remove("active");
      }
    }

    setHandlers = () => {
      switch(this.target) {
        case "prev":
          this.action = () => this.parent.carousel.incrementActiveItem(-1);
          break;
        case "next":
          this.action = () => this.parent.carousel.incrementActiveItem(1);
          break;
        default:
          this.action = () => this.parent.carousel.setActiveItem(this.target);
          break;
      }

      this.view.root.addEventListener("click",this.action); 
      this.parent.carousel.view.root.addEventListener("carouselSetActive",(e) => this.maybeSetActive(e.detail.active));
    }
  }

  class CarouselNav {
    constructor(carousel) {
      this.carousel = carousel;
      this.view = {
        root: undefined
      }

      this.build();
    }

    build = () => {
      const carouselControls = document.createElement("nav");
      carouselControls.classList.add("carousel__nav");
      const carouselControlsList = document.createElement("ul");
      carouselControlsList.classList.add("nav__items");
      carouselControls.appendChild(carouselControlsList);

      this.carousel.view.root.after(carouselControls);
      this.view.root = carouselControls;
      this.view.list = carouselControlsList;
    
      ["prev",...this.carousel.items.map((item,i) => i),"next"].forEach((navItem,n) => new CarouselNavItem(this,navItem));
    }
  }

  class Carousel {
    constructor(el) {
      this.view = {
        root: el
      }

      this.active = undefined;
      this.items = Array.from(this.view.root.children);

      this.build();
      this.setActiveItem(0);
      this.setHandlers();
    }

    build = () => {
      this.appendWrapper();
      new CarouselNav(this);
      this.view.parent = this.view.root.closest(".post__full");
      this.appendImageWrappers();
    }

    appendImageWrappers = () => {
      const items = this.view.root.querySelectorAll(".wp-block-image");
      Array.from(items).forEach(item => {
        const wrapper = document.createElement("div");
        wrapper.classList.add("carousel__image__wrapper");
        const itemImage = item.querySelector("img");
        itemImage.after(wrapper);
        wrapper.appendChild(itemImage);
      });
    }

    appendWrapper = () => {
      const carouselWrapper = document.createElement("div");
      carouselWrapper.classList.add("carousel__wrapper");
      this.view.root.after(carouselWrapper);
      carouselWrapper.appendChild(this.view.root);
      this.view.wrapper = carouselWrapper;
    }

    setActiveItem = i => {
      if (i === undefined) i = this.active;

      this.view.root.dispatchEvent(new CustomEvent("carouselSetActive",{detail:{active:i}}));

      if (this.active !== undefined && i !== this.active) {
        this.view.wrapper.classList.remove(`carousel__wrapper-active-${this.active}`);
        this.items[this.active].classList.remove("active"); 
      }

      const newActiveItem = this.items[i];
      newActiveItem.classList.add("active");
      this.view.root.style.left = `${-1 * newActiveItem.offsetLeft}px`;

      this.active = i;
      this.view.wrapper.classList.add(`carousel__wrapper-active-${this.active}`);
    }

    incrementActiveItem = amt => {
      if (this.active !== undefined) {
        let newActive = this.active + amt;
        if (this.active + amt < 0) {
          newActive = this.items.length - 1;
        } else if (this.active + amt > this.items.length -1) {
          newActive = 0;
        }
        this.setActiveItem(newActive);
      }
    }

    setHandlers = () => {
      this.view.wrapper.addEventListener('swiped-right', () => this.incrementActiveItem(-1));
      this.view.wrapper.addEventListener('swiped-left', () => this.incrementActiveItem(1));

      this.view.parent.addEventListener("resetPost",() => this.setActiveItem(0));

      let resizeTimeout;

      window.addEventListener("resize",() => {
        if (resizeTimeout) clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(() => {
          this.setActiveItem();
        },1000);
      });
    }
  }

  const carousels = document.querySelectorAll('.post__full .wp-block-gallery.has-nested-images');
  carousels.forEach(carousel => new Carousel(carousel));

})()